import {Grid, InputAdornment} from '@material-ui/core'
import React, {useCallback, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Field} from 'redux-form'
import {PARAMETERS_TYPE, SPECIES} from '../../../appConst'
import Headline from '../../../components/Headline'
import {OutlinedFormInput} from '../../../components/redux-form'
import Form from '../../../components/redux-form/Form'
import {combineValidators, validateMandatoryFields, validateNumberRange} from '../../../utils/form'
import {handleSubmitAnimalParameters} from '../actions'

const highRangeFields = [
    'total',
    'forfait'
]
const regularRangeFields = [
    'criteria'
]
const percentageFields = [
]
const allFields = [
    ...highRangeFields,
    ...regularRangeFields,
    ...percentageFields
]

const validateMandatory = validateMandatoryFields(allFields)
const validateHighRanges = validateNumberRange(highRangeFields, 0, 99999999)
const validateRegularRanges = validateNumberRange(regularRangeFields, 0, 9999)
const validatePercentages = validateNumberRange(percentageFields, 0, 100)
const _validate = combineValidators([
    validateMandatory,
    validateHighRanges,
    validateRegularRanges,
    validatePercentages
])
const validate = ({...values}) => {
    const errors = _validate(values)
    return errors
}

const GoatParameters = ({parameters, disabled}) => {
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = useCallback((values, dispatch) => {
        setSubmitting(true)
        return handleSubmitAnimalParameters(SPECIES.GOAT, values)(dispatch).finally(() => {
            setSubmitting(false)
        })
    }, [setSubmitting])

    return (
            <Form
                    form="goatParametersForm"
                    initialValues={parameters}
                    enableReinitialize
                    validate={validate}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    showCancelButton={!disabled}
            >
                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.title`}/>
                </Headline>

                <Grid container>
                    <Grid item xs={4}>
                        <Field
                                name="total"
                                label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.total`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                }}
                                min={0}
                                max={99999999}
                                step={0.01}
                                disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                                name="criteria"
                                label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.criteria`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">IA dans le lot</InputAdornment>
                                }}
                                min={0}
                                max={9999}
                                step={1}
                                disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                                name="forfait"
                                label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.GOAT}.forfait`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                }}
                                min={0}
                                max={9999}
                                step={0.01}
                                disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Form>
    )
}

export default GoatParameters
