import {Grid, InputAdornment} from '@material-ui/core'
import React, {useCallback, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Field} from 'redux-form'
import {PARAMETERS_TYPE, SPECIES} from '../../../appConst'
import Headline from '../../../components/Headline'
import {OutlinedFormInput} from '../../../components/redux-form'
import Form from '../../../components/redux-form/Form'
import {combineValidators, validateMandatoryFields, validateNumberRange} from '../../../utils/form'
import {handleSubmitAnimalParameters} from '../actions'

export const FIELD_ENVELOPPE_GLOBALE_TERRITORIALE = 'enveloppeGlobaleTerritoriale'
export const FIELD_ENVELOPPE_GLOBALE_RACIALE = 'enveloppeGlobaleRaciale'
export const FIELD_ICHN_ZONE_O = 'ichnForfaitO'
export const FIELD_ICHN_ZONE_P = 'ichnForfaitP'
export const FIELD_ICHN_ZONE_M = 'ichnForfaitM'
export const FIELD_ICHN_ZONE_HM = 'ichnForfaitHm'
export const FIELD_ICHN_ZONE_ILE = 'ichnForfaitIle'
export const FIELD_DENSITE_ZONE_O = 'densiteForfaitO'
export const FIELD_DENSITE_ZONE_P = 'densiteForfaitP'
export const FIELD_DENSITE_ZONE_M = 'densiteForfaitM'
export const FIELD_DENSITE_ZONE_HM = 'densiteForfaitHm'
export const FIELD_DENSITE_ZONE_ILE = 'densiteForfaitIle'
export const FIELD_NB_KM_MIN = 'nbKmMin'
export const FIELD_NB_KM_MAX = 'nbKmMax'
export const FIELD_FORFAIT_RACE_MENACEE = 'forfaitRaceMenacee'
export const FIELD_CRITERE_ELIGIBILITE_ICHN = 'critereEligibiliteIchn'
export const FIELD_CRITERE_ELIGIBILITE_DENSITE = 'critereEligibiliteDensite'

const SECTION_COMPENSATION_ACTE = 'compensationActe'
const SECTION_COMPENSATION_DENSITE = 'compensationDensite'

const highRangeFields = [
    FIELD_ENVELOPPE_GLOBALE_TERRITORIALE,
    FIELD_ENVELOPPE_GLOBALE_RACIALE
]
const intermediateRangeFields = []
const regularRangeFields = [
    FIELD_ICHN_ZONE_O,
    FIELD_ICHN_ZONE_P,
    FIELD_ICHN_ZONE_M,
    FIELD_ICHN_ZONE_HM,
    FIELD_ICHN_ZONE_ILE,
    FIELD_DENSITE_ZONE_O,
    FIELD_DENSITE_ZONE_P,
    FIELD_DENSITE_ZONE_M,
    FIELD_DENSITE_ZONE_HM,
    FIELD_DENSITE_ZONE_ILE,
    FIELD_NB_KM_MIN,
    FIELD_NB_KM_MAX,
    FIELD_FORFAIT_RACE_MENACEE,
    FIELD_CRITERE_ELIGIBILITE_DENSITE
]
const smallRangeFields = []
const percentageFields = [
    FIELD_CRITERE_ELIGIBILITE_ICHN
]
const allFields = [
    ...highRangeFields,
    ...intermediateRangeFields,
    ...regularRangeFields,
    ...smallRangeFields,
    ...percentageFields
]

const validateMandatory = validateMandatoryFields(allFields)
const validateHighRanges = validateNumberRange(highRangeFields, 0, 99999999)
const validateIntermediateRangeFields = validateNumberRange(intermediateRangeFields, 0, 9999999)
const validateRegularRanges = validateNumberRange(regularRangeFields, 0, 9999)
const validateSmallRanges = validateNumberRange(smallRangeFields, 0, 1000)
const validatePercentages = validateNumberRange(percentageFields, 0, 100)
const _validate = combineValidators([
    validateMandatory,
    validateHighRanges,
    validateIntermediateRangeFields,
    validateRegularRanges,
    validateSmallRanges,
    validatePercentages
])

const validate = ({
                      [SECTION_COMPENSATION_ACTE]: compensationActe,
                      [SECTION_COMPENSATION_DENSITE]: compensationDensite,
                      ...values
                  }) => {
    console.log(compensationActe, compensationDensite, values)
    const errors = _validate(values)

    errors[SECTION_COMPENSATION_ACTE] = combineValidators([validateMandatory, validateRegularRanges])(compensationActe || {})
    errors[SECTION_COMPENSATION_DENSITE] = combineValidators([validateMandatory, validateRegularRanges])(compensationDensite || {})

    return errors
}

const BovineParameters = ({parameters, disabled}) => {
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = useCallback((values, dispatch) => {
        setSubmitting(true)
        return handleSubmitAnimalParameters(
                SPECIES.BOVINE,
                {
                    ...values,
                    [FIELD_CRITERE_ELIGIBILITE_ICHN]: values[FIELD_CRITERE_ELIGIBILITE_ICHN] / 100
                }
        )(dispatch).finally(() => {
            setSubmitting(false)
        })
    }, [setSubmitting])

    return (
            <Form
                    form="bovineParametersForm"
                    initialValues={parameters}
                    enableReinitialize
                    validate={validate}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    showCancelButton={!disabled}
            >
                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialActe.title`}/>
                </Headline>

                <Grid container>
                    {[
                        FIELD_ICHN_ZONE_O,
                        FIELD_ICHN_ZONE_P,
                        FIELD_ICHN_ZONE_M,
                        FIELD_ICHN_ZONE_HM,
                        FIELD_ICHN_ZONE_ILE
                    ].map(field => (
                            <Grid item xs={4} key={field}>
                                <Field
                                        name={field}
                                        label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialActe.${field}`}/>}
                                        component={OutlinedFormInput}
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                                        }}
                                        min={0}
                                        max={9999}
                                        step={0.01}
                                        disabled={disabled}
                                />
                            </Grid>
                    ))}

                    <Grid item xs={4}>
                        <Field
                                name={FIELD_CRITERE_ELIGIBILITE_ICHN}
                                label={<FormattedMessage
                                        id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialActe.${FIELD_CRITERE_ELIGIBILITE_ICHN}`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                }}
                                min={0}
                                max={100}
                                step={0.01}
                                disabled={disabled}
                        />
                    </Grid>
                </Grid>

                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialDensite.title`}/>
                </Headline>

                <Grid container>
                    {[
                        FIELD_DENSITE_ZONE_O,
                        FIELD_DENSITE_ZONE_P,
                        FIELD_DENSITE_ZONE_M,
                        FIELD_DENSITE_ZONE_HM,
                        FIELD_DENSITE_ZONE_ILE
                    ].map(field => (
                            <Grid item xs={4} key={field}>
                                <Field
                                        name={field}
                                        label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialDensite.${field}`}/>}
                                        component={OutlinedFormInput}
                                        type="number"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                                        }}
                                        min={0}
                                        max={9999}
                                        step={0.01}
                                        disabled={disabled}
                                />
                            </Grid>
                    ))}

                    <Grid item xs={4}>
                        <Field
                                name={FIELD_CRITERE_ELIGIBILITE_DENSITE}
                                label={<FormattedMessage
                                        id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialDensite.${FIELD_CRITERE_ELIGIBILITE_DENSITE}`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">IA/km²</InputAdornment>
                                }}
                                min={0}
                                max={100}
                                step={0.01}
                                disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Field
                                name={FIELD_NB_KM_MIN}
                                label={<FormattedMessage
                                        id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialDensite.${FIELD_NB_KM_MIN}`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">km/IA</InputAdornment>
                                }}
                                min={0}
                                max={9999}
                                step={0.1}
                                disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Field
                                name={FIELD_NB_KM_MAX}
                                label={<FormattedMessage
                                        id={`parameters.${PARAMETERS_TYPE.BOVINE}.territorialDensite.${FIELD_NB_KM_MAX}`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">km/IA</InputAdornment>
                                }}
                                min={0}
                                max={9999}
                                step={1}
                                disabled={disabled}
                        />
                    </Grid>
                </Grid>

                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.axeRacial.title`}/>
                </Headline>

                <Grid container>
                    <Grid item xs={4}>
                        <Field
                                name={FIELD_FORFAIT_RACE_MENACEE}
                                label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.axeRacial.${FIELD_FORFAIT_RACE_MENACEE}`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                }}
                                min={0}
                                max={9999}
                                step={0.01}
                                disabled={disabled}
                        />
                    </Grid>
                </Grid>

                <Headline>
                    <FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.bilanBovin.title`}/>
                </Headline>

                <Grid container>
                    <Grid item xs={4}>
                        <Field
                                name={FIELD_ENVELOPPE_GLOBALE_TERRITORIALE}
                                label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.bilanBovin.${FIELD_ENVELOPPE_GLOBALE_TERRITORIALE}`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                }}
                                min={0}
                                max={99999999}
                                step={0.01}
                                disabled={disabled}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Field
                                name={FIELD_ENVELOPPE_GLOBALE_RACIALE}
                                label={<FormattedMessage id={`parameters.${PARAMETERS_TYPE.BOVINE}.bilanBovin.${FIELD_ENVELOPPE_GLOBALE_RACIALE}`}/>}
                                component={OutlinedFormInput}
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                                }}
                                min={0}
                                max={99999999}
                                step={0.01}
                                disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Form>
    )
}

export default BovineParameters
