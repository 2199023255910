import { SubmissionError } from 'redux-form'
import { PARAMETERS_TYPE } from '../../appConst'
import { snackError, snackSuccess } from '../../global/snackActions'
import middle from '../../middlewares/api/middle'
import { createGeneralParameters as createGeneralParametersApi, updateAnimalParameters as updateAnimalParametersApi, updateGeneralParameters as updateGeneralParametersApi } from './api'

const prefix = 'PARAMETERS/'

export const GET_PARAMETERS = `${prefix}GET_PARAMETERS`
export const GET_PARAMETERS_LOADING = `${prefix}GET_PARAMETERS_LOADING`
export const GET_PARAMETERS_ERROR = `${prefix}GET_PARAMETERS_ERROR`
export const UPDATE_PARAMETERS = `${prefix}UPDATE_PARAMETERS`
export const CREATE_PARAMETERS = `${prefix}CREATE_PARAMETERS`

export const getParameters = (year, species) => {
	const paramType = (
		(species === PARAMETERS_TYPE.GOAT) ?
			'goat_parameters' : ((species === PARAMETERS_TYPE.BOVINE) ? 'bovine_parameters' : 'general_parameters')
	)
	return middle(GET_PARAMETERS, {
		api: '/api/admin',
		url: `/${paramType}?year=${year}`
	})
}

export const updateAnimalParameters = (species, values) => dispatch => {
    return updateAnimalParametersApi(species, values)
        .then(res => {
            dispatch({
                type: UPDATE_PARAMETERS,
                payload: { ...res, species }
            })
            return res
        })
}

export const handleSubmitAnimalParameters = (species, values) => (dispatch) => {
    return updateAnimalParameters(species, values)(dispatch).then(() => {
        dispatch(snackSuccess('parameters.success'))
    }, (e) => {
        dispatch(snackError())
        throw new SubmissionError(e)
    })
}

export const createGeneralParameters = (values) => (dispatch) => {
    return createGeneralParametersApi(values)
        .then((res) => {
            dispatch({
                type: CREATE_PARAMETERS,
                payload: res
            })
            return res
        })
}

export const updateGeneralParameters = (id, values) => (dispatch) => {
    return updateGeneralParametersApi(id, values)
        .then((res) => {
            dispatch({
                type: UPDATE_PARAMETERS,
                payload: res
            })
            return res
        })
}
