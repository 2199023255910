import { fetchFactory, postMultipart } from 'isotope-client'
import { SPECIES } from '../../appConst'

const getSpeciesUrl = (species) => (species === SPECIES.BOVINE) ? 'bovine_parameters' : 'goat_parameters'

export const updateAnimalParameters = (species, values) => fetchFactory(`/${getSpeciesUrl(species)}`, {
	method: 'POST',
	body: JSON.stringify(values)
})

export const updateGeneralParameters = (id, values) => postMultipart(`/general_parameters/${id}`, {
	body: values
})

export const createGeneralParameters = (values) => postMultipart('/general_parameters', {
	body: values
})
